import React from "react";

function RefundPageBody() {
  const containerStyle = {
    textAlign: "start",
    color: "rgb(0, 0, 0)",
    backgroundColor: "rgb(255, 255, 255)",
    fontSize: "16px",
    paddingBottom: "20px",

  };

  const headingStyle = {
    fontSize: "22px",
    marginBottom: "2px",
  };

  return (
    <div className="container">
      <div className="tos">
        <div style={containerStyle}>
          <div>
          <h3 style={headingStyle}>Refund Policy:</h3>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ fontSize: "15px" }}>
                If you encounter any dissatisfaction with our service, please contact our support department for assistance. We strive to resolve issues, and if the problem persists, you are eligible for a refund within 7 days. Refunds will not be processed beyond this 7-day period.
              </li>
              <li style={{ fontSize: "15px" }}>
                Refunds are not applicable to .COM, .NET, .ORG, or any other domain names, SSL Certificates, Domain Transfer In/Out fees, or services from other companies associated with Softhab.
              </li>
              <li style={{ fontSize: "15px" }}>
                Approval for refunds is limited to hosting-related issues only. Refunds are not provided for content, device, or internet connection issues.
              </li>
              <li style={{ fontSize: "15px" }}>
                No refunds are applicable to VPS or Dedicated servers under any circumstances.
              </li>
              <li style={{ fontSize: "15px" }}>
                Termination of hosting or other services due to a violation of our Acceptable Use Policy or other Terms and Conditions does not qualify for a refund, except as specified therein. Statutory rights remain unaffected.
              </li>
              <li style={{ fontSize: "15px" }}>
                Credit on your account cannot be refunded and must be utilized for purchases and renewals.
              </li>
              <li style={{ fontSize: "15px" }}>
                Dedicated servers are exempt from the 7-day refund policy, and refunds are issued at our discretion. Setup fees are non-refundable.
              </li>
              <li style={{ fontSize: "15px" }}>
                No refunds will be made for any gateway fees, such as bkash, rocket 2% extra gateway fee, or any credit card gateway fees.
              </li>
              <li style={{ fontSize: "15px" }}>
                Accounts purchased under a promotion, with or without a promo code, are excluded from the 7-day money-back guarantee.
              </li>
              <li style={{ fontSize: "15px" }}>
                Excess funds sent to Softhab will only be reimbursed in credit and cannot be refunded.
              </li>
            </ul>
          </div>
        </div>
        <div style={containerStyle}>
          <div>
            <strong>
              <h3 style={headingStyle}>Cancellation Procedure:</h3>
            </strong>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ fontSize: "15px" }}>
                Cancellation requests must be submitted through the client area. Click on the icon next to the package, press 'Request Cancellation,' and complete the form.
              </li>

              <li style={{ fontSize: "15px" }}>
                Immediate cancellation results in service termination within 24 hours of form submission.
              </li>

              <li style={{ fontSize: "15px" }}>
                It is your responsibility to ensure the cancellation of any PayPal subscriptions.
              </li>

              <li style={{ fontSize: "15px" }}>
                No other forms of cancellation are accepted.
              </li>

              <li style={{ fontSize: "15px" }}>
                Cancellations must be submitted before the invoice for that billing cycle is due. Failure to do so will result in the invoice becoming due. Cancellation requests can only be submitted if the account has no due invoices.
                <br/><br/><i>Updated at: September 03, 2024</i>
              </li>
              
            </ul>
          </div>
        </div>
      </div>

      <br/><br/>
      <div className="tos">
        <div style={containerStyle}>
          <div>
          <h3 style={headingStyle}>মূল্যফেরত/রিফান্ড বিষয়ক:</h3>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ fontSize: "15px" }}>
              আপনি যদি আমাদের পরিষেবার সাথে কোনো অসন্তোষের সম্মুখীন হন, তাহলে সহায়তার জন্য আমাদের সহায়তা বিভাগের সাথে যোগাযোগ করুন। আমরা সমস্যাগুলি সমাধান করার চেষ্টা করি এবং যদি সমস্যাটি থেকে যায়, আপনি ৭ দিনের মধ্যে ফেরত পাওয়ার জন্য যোগ্য৷ এই ৭ দিনের সময়ের পরে ফেরত প্রক্রিয়া করা হবে না।
              </li>
              <li style={{ fontSize: "15px" }}>
              .COM, .NET, .ORG, বা অন্য কোন ডোমেইন নাম, SSL সার্টিফিকেট, ডোমেইন ট্রান্সফার ইন/আউট ফি, বা Softhab-এর সাথে যুক্ত অন্যান্য কোম্পানির পরিষেবার জন্য রিফান্ড প্রযোজ্য নয়।
              </li>
              <li style={{ fontSize: "15px" }}>
              রিফান্ডের অনুমোদন শুধুমাত্র হোস্টিং-সম্পর্কিত সমস্যাগুলির মধ্যে সীমাবদ্ধ। বিষয়বস্তু, ডিভাইস বা ইন্টারনেট সংযোগ সমস্যার জন্য অর্থ ফেরত দেওয়া হয় না।
              </li>
              <li style={{ fontSize: "15px" }}>
              কোনো অবস্থাতেই VPS বা ডেডিকেটেড সার্ভারে কোনো রিফান্ড প্রযোজ্য নয়।
              </li>
              <li style={{ fontSize: "15px" }}>
              আমাদের গ্রহণযোগ্য ব্যবহারের নীতি বা অন্যান্য নিয়ম ও শর্তাবলী লঙ্ঘনের কারণে হোস্টিং বা অন্যান্য পরিষেবার সমাপ্তি সেখানে উল্লেখ করা ব্যতীত অর্থ ফেরতের জন্য যোগ্য নয়। সংবিধিবদ্ধ অধিকার অপ্রভাবিত থাকে।
              </li>
              <li style={{ fontSize: "15px" }}>
              আপনার অ্যাকাউন্টে ক্রেডিট ফেরত দেওয়া যাবে না এবং ক্রয় এবং পুনর্নবীকরণের জন্য ব্যবহার করা আবশ্যক।
              </li>
              <li style={{ fontSize: "15px" }}>
               ডেডিকেটেড সার্ভারগুলি ৭-দিনের ফেরত নীতি থেকে অব্যাহতিপ্রাপ্ত, এবং আমাদের বিবেচনার ভিত্তিতে ফেরত জারি করা হয়। সেটআপ ফি অ ফেরতযোগ্য.
              </li>
              <li style={{ fontSize: "15px" }}>
                কোনো গেটওয়ে ফি যেমন বিকাশ, রকেট ২% অতিরিক্ত গেটওয়ে ফি বা ক্রেডিট কার্ড গেটওয়ে ফি এর জন্য কোনো ফেরত দেওয়া হবে না।
              </li>
              <li style={{ fontSize: "15px" }}>
              একটি প্রচারের অধীনে ক্রয় করা অ্যাকাউন্টগুলি, একটি প্রচার কোড সহ বা ছাড়া, ৭ দিনের অর্থ ফেরত গ্যারান্টি থেকে বাদ দেওয়া হয়েছে৷
              </li>
              <li style={{ fontSize: "15px" }}>
              Softhab-এ পাঠানো অতিরিক্ত তহবিল শুধুমাত্র ক্রেডিট হিসাবে পরিশোধ করা হবে এবং ফেরত দেওয়া যাবে না।
              </li>
            </ul>
          </div>
        </div>
        <div style={containerStyle}>
          <div>
            <strong>
              <h3 style={headingStyle}>বাতিল করার পদ্ধতি:</h3>
            </strong>
            <ul style={{ listStyleType: "none" }}>
              <li style={{ fontSize: "15px" }}>
              বাতিলকরণের অনুরোধ ক্লায়েন্ট এলাকার মাধ্যমে জমা দিতে হবে। প্যাকেজের পাশের আইকনে ক্লিক করুন, 'রিকোয়েস্ট ক্যান্সেলেশন' টিপুন এবং ফর্মটি পূরণ করুন৷
              </li>

              <li style={{ fontSize: "15px" }}>
              তাৎক্ষণিক বাতিলের ফলে ফর্ম জমা দেওয়ার 24 ঘন্টার মধ্যে পরিষেবা বন্ধ হয়ে যায়।
              </li>

              <li style={{ fontSize: "15px" }}>
              পেপ্যাল ​​সাবস্ক্রিপশন বাতিল করা নিশ্চিত করা আপনার দায়িত্ব।
              </li>

              <li style={{ fontSize: "15px" }}>
              বাতিলের অন্য কোন ফর্ম গ্রহণ করা হয় না.
              </li>

              <li style={{ fontSize: "15px" }}>
              সেই বিলিং চক্রের জন্য চালান জমা দেওয়ার আগে বাতিল অবশ্যই জমা দিতে হবে। তা করতে ব্যর্থ হলে চালান বকেয়া হয়ে যাবে। বাতিলকরণের অনুরোধ শুধুমাত্র জমা দেওয়া যাবে যদি অ্যাকাউন্টের কোনো বকেয়া চালান না থাকে।
                <br/><br/><i>আপডেট করা হয়েছে: ৩ সেপ্টেম্বর, ২০২৪</i>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RefundPageBody;